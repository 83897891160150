<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="对账单号" :class="$i18n.locale">
              <el-input
                v-model="form.checkBillCode"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorId"
                value-key="id"
                clearable
                filterable
                multiple
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" :class="$i18n.locale">
              <el-select
                v-model="form.status"
                value-key="id"
                clearable
                filterable
                multiple
              >
                <el-option v-for="item in _getAllCommodityDict('CHECK_BILL_STATUS')" :key="item.val" :label="item.label" :value="item.val" />
                <!--                 <el-option label="对账中" value="对账中" />
                <el-option label="已对账" value="已对账" />val
                <el-option label="已作废" value="已作废" /> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="对账人">
              <el-select
                v-model="form.checkBillUser"
                value-key="id"
                clearable
                filterable
                multiple
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.nickName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="对账时间" :class="$i18n.locale">
              <el-date-picker
                v-model="value1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ImportButton export-key="scm_check_bill" :params="checkBillPageParams" />
            <ImportButton export-key="scm_check_bill_detail" btn-type="primary" :params="exportDetailFormParams" export-name="导出明细" :before-export="handleBeforeExport" />

            <el-button
              type="primary"
              @click="handleNoRecon()"
            >未对账单</el-button>
            <el-button
              type="primary"
              @click="handleResetRecon()"
            >撤销对账</el-button>
            <el-button
              type="primary"
              @click="handleReview()"
            >对账复核</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick(0)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <table-select-action
      :quantity="multipleSelection.length"
      @reset="handleTableSelectReset"
    />
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="500px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="50"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="checkBillCode"
        label="对账单号"
        align="center"
        sortable
        min-width="115"
      />
      <el-table-column
        prop="vendorName"
        label="供应商"
        width="100"
        align="center"
        sortable
      />
      <!--  <el-table-column
        prop="currencyTypeI18"
        label="币种"
        width="90"
        align="center"
        sortable
      /> -->
      <el-table-column prop="currencyType" label="币种" width="120" align="center" sortable>
        <template slot-scope="scope">
          {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="totalMoney"
        label="对账金额"
        width="130"
        align="center"
        sortable
      />
      <el-table-column
        prop="totalNumber"
        label="对账数量"
        width="130"
        align="center"
        sortable
      />
      <el-table-column
        prop="status"
        label="状态"
        width="90"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status===1">对账中</span>
          <span v-else-if="scope.row.status===2">已对账</span>
          <span v-else-if="scope.row.status===9">已作废</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="checkBillUser"
        label="对账人"
        width="130"
        align="center"
        sortable
      />
      <el-table-column
        prop="checkBillTime"
        label="对账时间"
        width="130"
        align="center"
        sortable
      />
      <el-table-column
        prop="purchasePersonnel"
        label="操作"
        fixed="right"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="handleClick(scope.row.id)"
          >查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 撤销对账弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="handleResetReconVisible"
      width="30%"
    >
      <span>确定撤销本次对账单吗，撤销后单据会变成已作废</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleResetReconVisible = false">取 消</el-button>
        <el-button type="primary" :loading="handleResetReconSureLoading" @click="handleResetReconSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 对账复核弹框 -->
    <el-dialog :visible.sync="handleReviewDialogVisible" width="30%">
      <span>本次对账供应商{{ vendorNameList }}本次对账金额{{ sum }} 确定之后生成代付款</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleReviewDialogVisible = false">{{
          $t("title.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleReviewSure">{{
          $t("page.sure")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="handlePagination"
      />
    </div>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import TableSelectAction from '@/components/TableSelectAction'
import Pagination from '@/components/Pagination'
import { queryVendorList, checkBillPage, usersPage, cancelCheckBill, checkBillReview, ExcelExport } from '@/api/scm-api'
import ImportButton from '@/components/ExportFile'
export default {
  components: { TableSelectAction, Pagination, ImportButton },
  mixins: [commodityInfoDict],
  data() {
    return {
      handleReviewDialogVisible: false,
      handleResetReconSureLoading: false,
      handleResetReconVisible: false,
      styleOptions: [],
      form: {},
      value1: [],
      showRow: false,
      multipleSelection: [],
      TableLoading: false,
      tableDatas: [],
      pager: {
        total: 0,
        current: 1,
        size: 10
      },
      vendorOptions: [],
      vendorNameList: [],
      sum: 0,
      userList: []
    }
  },
  computed: {
    checkBillPageParams() {
      const [startCheckBillDate, endCheckBillDate] = this.value1 || []
      const { checkBillCode, vendorId, status, checkBillUser } = this.form
      return Object.assign(
        {},
        { checkBillCode, vendorId: vendorId ? vendorId.toString() : '', status: status ? status.toString() : '', checkBillUser: checkBillUser ? checkBillUser.toString() : '' },
        { startCheckBillDate, endCheckBillDate },
        this.pager
      )
    },
    exportDetailFormParams() {
      const arr = []
      this.multipleSelection.map(item => arr.push(item.checkBillCode))
      return Object.assign({}, { checkBillCode: arr?.join() })
    }

  },
  created() {},
  mounted() {
    this._queryVendorList()
    this._usersPage()
    this.queryClick(0)
  },
  methods: {

    // 对账人
    async _usersPage() {
      const { datas } = await usersPage()
      this.userList = datas
    },
    //   供应商下拉数据
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    async handleReviewSure() {
      const arr = []
      this.multipleSelection.map(item => arr.push(item.checkBillCode))
      const { code } = await checkBillReview({ codes: arr.toString() })
      code === 0 ? this.$notify({
        title: '成功',
        message: `成功`,
        type: 'success'
      }) : this.$notify({
        title: '失败',
        message: '操作失败',
        type: 'error'
      })
      this.handleReviewDialogVisible = false
      this.queryClick(0)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleClick(id) {
      const routeData = this.$router.resolve({
        path: 'detail',
        append: true,
        query: {
          id
        }
      })
      window.open(routeData.href, '_blank')
    },
    //   分页查询
    async queryClick(type) {
      try {
        type === 0 ? this.pager.current = 1 : ''
        this.TableLoading = true
        const { datas } = await checkBillPage(this.checkBillPageParams)
        // console.log(datas)
        const { records, pager } = datas
        this.tableDatas = records
        // console.log(pager);
        const { current, size, total } = pager
        this.pager = { current, size, total }
        this.TableLoading = false
      } finally {
        this.TableLoading = false
      }
    },
    // 重置
    resetClick() {
      this.form = {}
      this.value1 = []
      this.queryClick(0)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    handleBeforeExport() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请至少选中一条数据')
        return false
      }
    },
    // 未对账单
    handleNoRecon() {
      const routeData = this.$router.resolve({
        path: '../no-reconciliation',
        append: true
      })
      window.open(routeData.href, '_blank')
    },
    // 撤销对账
    handleResetRecon() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请至少选中一条数据')
        return
      }
      if (this.multipleSelection.some(item => item.status === 2)) {
        this.$message.warning('已对账单不允许撤销')
        return
      }
      this.handleResetReconVisible = true
    },
    async handleResetReconSure() {
      try {
        this.handleResetReconSureLoading = true
        const arr = []
        this.multipleSelection.map(item => arr.push(item.checkBillCode))
        const length = arr.length
        const { code } = await cancelCheckBill({ codes: arr.toString() })
        code === 0 ? this.$notify({
          title: '成功',
          message: `撤销成功${length}条对账单`,
          type: 'success'
        }) : this.$notify({
          title: '失败',
          message: '撤销对账失败',
          type: 'error'
        })
        this.handleResetReconSureLoading = false
        this.handleResetReconVisible = false
        this.queryClick(0)
      } catch (err) {
        console.log(err)
      } finally {
        this.handleResetReconSureLoading = false
      }
    },
    // 对账复核
    handleReview() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请至少选中一条数据')
        return
      }
      const arr = []
      this.multipleSelection.map(item => item.status === 9 ? arr.push(item.checkBillCode) : '')
      // console.log(arr);
      if (arr.length > 0) {
        return this.$message.error(arr.toString() + '是已作废订单，不可以复核')
      }
      this.vendorNameList = []
      this.sum = 0
      for (var i = 0; i < this.multipleSelection.length; i++) {
        this.sum += this.multipleSelection[i].totalMoney
      }
      this.multipleSelection.map((item) =>
        this.vendorNameList.push(item.vendorName)
      )
      this.vendorNameList = Array.from(new Set(this.vendorNameList)).toString()
      this.handleReviewDialogVisible = true
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      this.$refs.multipleTable.clearSelection()
    },
    handlePagination(val) {
      this.pager.current = val.current
      this.pager.size = val.size
      this.queryClick(1)
    }

  }
}
</script>

<style scoped lang="scss">
</style>
